import React from "react";
import { Grid, Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import VideoCard from "components/VideoCard";

function Pages() {
  const videoData = [
    { videoSrc: "videos/test.mp4", title: "Video 1" },
    { videoSrc: "videos/test.mp4", title: "Video 2" },
    { videoSrc: "videos/test.mp4", title: "Video 3" },
    { videoSrc: "videos/test.mp4", title: "Video 4" },
    // Add more video data here if needed
  ];

  const renderData = videoData.map(({ videoSrc, title }) => (
    <Grid
      item
      xs={12} // Full width on extra-small screens
      sm={12} // Half width on small screens and larger
      md={6} // Half width on medium screens and larger
      lg={12}
      xl={6}
      sx={{
        display: "flex",
        justifyContent: "center", // Centering the video card within the grid item
      }}
      key={title}
    >
      <MKBox
        sx={{
          width: "100%",
          maxWidth: "420px", // Fixed maximum width for the video cards
        }}
      >
        <VideoCard videoSrc={videoSrc} />
      </MKBox>
    </Grid>
  ));

  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 4, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="see examples"
            container
            sx={{ mb: -15 }}
          />
        </Grid>
      </Container>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {renderData}
      </Grid>
    </MKBox>
  );
}

export default Pages;
