import React, { useContext, useEffect, useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Sidebar from "examples/Navbars/SideBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import { AuthContext } from "contexts/AuthContext";
import { changeSubscription, cancelSubscription } from "pages/pricing/subController";
import Modal from "@mui/material/Modal";
import MKButton from "components/MKButton";
import Backdrop from "@mui/material/Backdrop";
import InfoModal from "components/InfoModal"; // Import the new modal

function BillingPage() {
  const { user, fetchUser } = useContext(AuthContext);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoTitle, setInfoTitle] = useState("");

  const selectedRoutes = [routes[0], routes[2], routes[3], routes[4]];
  const selectedRoutesFoot = [routes[5], routes[1]];

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchUser();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [fetchUser]);

  const getPrice = (plan, cycle) => {
    const prices = {
      free: "$0.00",
      standard: cycle === "monthly" ? "$19.99" : "$199.99",
      pro: cycle === "monthly" ? "$39.99" : "$399.99",
    };
    return prices[plan];
  };

  const handleFreePlanSwitch = async () => {
    try {
      await cancelSubscription(user.subscriptionId); // Cancel on PayPal
      await changeSubscription("free", "none", null); // Update in the backend and set subscriptionId to null
      showModal("Your subscription has been switched to the free plan.");
      setOpenConfirmModal(false);
    } catch (error) {
      showModal("Error switching to the free plan. Please try again.", error);
    }
  };

  const showModal = (message, title = "Notice") => {
    setInfoMessage(message);
    setInfoTitle(title);
    setOpenInfoModal(true);
  };

  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  return (
    <MKBox display="flex" minHeight="100vh">
      <Sidebar routes={selectedRoutes} footroutes={selectedRoutesFoot} />
      <MKBox
        sx={{
          marginLeft: "0px",
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mt: 5,
        }}
      >
        <MKBox
          borderRadius="lg"
          shadow="xl"
          p={3}
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
            backgroundColor: "white",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKTypography variant="h4" textAlign="left">
            Billing Information
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mb={4}>
            Manage your subscription and billing details.
          </MKTypography>

          {user && (
            <>
              <MKTypography variant="h5" mb={2} color="dark">
                {`Hello, ${user.displayName}`}
              </MKTypography>
              <MKTypography variant="body1" mb={2} color="textSecondary">
                Free Trials Remaining: <strong>{user.freeTrials || 0}</strong>
              </MKTypography>
              <MKTypography variant="body1" mb={2} color="textSecondary">
                Current Subscription: <strong>{`${user.subscription}`}</strong>
              </MKTypography>
              <MKTypography variant="body1" mb={4} color="textSecondary">
                Amount Charged:{" "}
                <strong>{`${getPrice(user.subscription, user.billingCycle)}`}</strong>{" "}
                <strong>{`${user.billingCycle}`}</strong>
              </MKTypography>

              {/* Show Cancel option only if not on free plan */}
              {user.subscription !== "free" && (
                <MKTypography
                  variant="body2"
                  color="error"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleOpenConfirmModal}
                >
                  Cancel Subscription
                </MKTypography>
              )}
            </>
          )}
        </MKBox>

        <MKBox width="100%" mt="auto">
          <SimpleFooter dark />
        </MKBox>

        <InfoModal
          open={openInfoModal}
          handleClose={() => setOpenInfoModal(false)}
          message={infoMessage}
          title={infoTitle}
        />

        {/* Confirmation Modal for Switching to Free Plan */}
        <Modal
          open={openConfirmModal}
          onClose={handleCloseConfirmModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <MKBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
              borderRadius: "lg",
            }}
          >
            <MKTypography variant="h6" mb={2}>
              Are you sure you want to switch to the Free Plan?
            </MKTypography>
            <MKBox mt={2} display="flex" justifyContent="space-between">
              <MKButton color="info" onClick={handleCloseConfirmModal}>
                Cancel
              </MKButton>
              <MKButton color="error" onClick={handleFreePlanSwitch}>
                Confirm
              </MKButton>
            </MKBox>
          </MKBox>
        </Modal>
      </MKBox>
    </MKBox>
  );
}

export default BillingPage;
