import React from "react";
import PropTypes from "prop-types";
import { CardMedia } from "@mui/material";

function VideoCard({ videoSrc }) {
  return (
    <CardMedia
      component="video"
      src={videoSrc}
      controls
      sx={{
        width: "100%", // Full width of the container
        height: "100%", // Full height of the container
        borderRadius: "12px", // Ensure all corners are rounded
        overflow: "hidden", // Prevents content from overflowing rounded corners
        objectFit: "cover", // Ensures the video covers the area while maintaining aspect ratio
      }}
    />
  );
}

VideoCard.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VideoCard;
