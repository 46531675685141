import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  FormControl,
  Grid,
  Switch,
  Modal,
  Backdrop,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import { AuthContext } from "contexts/AuthContext";
import bgImage from "assets/images/background.png";
import SignInModal from "components/SignInModal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { changeSubscription, cancelSubscription } from "./subController";
import InfoModal from "components/InfoModal"; // Import the new modal

function Pricing() {
  const { user, fetchUser } = useContext(AuthContext);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openPayPalModal, setOpenPayPalModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState("");
  const [paypalKey, setPaypalKey] = useState(0);

  useEffect(() => {
    // Set up polling interval to fetch user every 5 seconds
    const intervalId = setInterval(() => {
      fetchUser();
    }, 5000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [fetchUser]);

  const handleBillingCycleChange = () => {
    setBillingCycle((prev) => (prev === "monthly" ? "yearly" : "monthly"));
    setPaypalKey((prev) => prev + 1);
  };

  const handleOpenSignInModal = () => setOpenSignInModal(true);
  const handleCloseSignInModal = () => setOpenSignInModal(false);

  const getPrice = (plan) => {
    const prices = {
      free: "$0.00",
      standard: billingCycle === "monthly" ? "$9.99" : "$99.99",
      pro: billingCycle === "monthly" ? "$19.99" : "$199.99",
    };
    return prices[plan];
  };

  const benefitsList = (benefits) => (
    <List sx={{ listStyle: "none", paddingLeft: 0 }}>
      {benefits.map((benefit, index) => (
        <ListItem
          key={index}
          sx={{ display: "flex", alignItems: "center", justifyContent: "left", mb: 1 }}
        >
          <CheckCircleIcon fontSize="medium" sx={{ color: "info.main", mr: 1 }} />
          <Typography variant="h6">{benefit}</Typography>
        </ListItem>
      ))}
    </List>
  );

  const handleOpenPayPalModal = (subscriptionType) => {
    if (subscriptionType === "free" && user?.subscription !== "free") {
      setOpenConfirmModal(true);
    } else {
      setCurrentSubscriptionType(subscriptionType);
      setOpenPayPalModal(true);
    }
  };

  const showModal = (message, title = "Notice") => {
    setInfoMessage(message);
    setInfoTitle(title);
    setOpenInfoModal(true);
  };

  const handleClosePayPalModal = () => {
    setOpenPayPalModal(false);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const getPlanId = (subscriptionType) => {
    const planIds = {
      standard:
        billingCycle === "monthly" ? "P-0HB029556K2032452M4L62RA" : "P-2Y513047U6761411TM4L62KA",
      pro: billingCycle === "monthly" ? "P-5FM52827AJ049035JM4L5AOI" : "P-5ST87117AP485803EM4L47JY",
    };
    return planIds[subscriptionType];
  };

  const handleFreePlanSwitch = async () => {
    try {
      await cancelSubscription(user.subscriptionId); // Cancel on PayPal
      await changeSubscription("free", "none", null); // Update in the backend and set subscriptionId to null
      showModal("Your subscription has been switched to the free plan.");
      setOpenConfirmModal(false);
    } catch (error) {
      showModal("Error switching to the free plan. Please try again.");
    }
  };

  const cardContent = (title, price, benefits, color, subscriptionType) => (
    <>
      <Typography variant="h4" align="center" sx={{ mb: 1, color: "primary" }}>
        {title}
      </Typography>
      <Typography variant="h1" align="center" sx={{ mb: 2, color: "dark" }}>
        {price}
      </Typography>
      {benefitsList(benefits)}

      <MKButton
        variant="contained"
        color={color}
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => handleOpenPayPalModal(subscriptionType)}
        disabled={user?.subscription === subscriptionType}
      >
        {user?.subscription === subscriptionType ? "Current Plan" : "Subscribe"}
      </MKButton>
    </>
  );

  const selectedRoutes = [routes[0], routes[3], routes[4]];

  return (
    <MKBox display="flex" flexDirection="column" minHeight="100vh">
      <DefaultNavbar
        routes={selectedRoutes}
        action={
          user
            ? {
                type: "internal",
                route: "/download-center",
                label: "Profile",
                color: "primary",
              }
            : {
                type: "internal",
                onClick: handleOpenSignInModal,
                label: "Sign In",
                color: "primary",
              }
        }
        sticky
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          paddingTop: { xs: "70px", sm: "80px", md: "90px" },
        }}
      />
      <MKBox
        sx={{
          mt: {
            xs: "-5vh",
            sm: "-10vh",
            md: "-15vh",
            lg: "-20vh",
            xl: "-25vh",
          },
        }}
        mb={4}
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <FormControl component="fieldset">
          <Grid container alignItems="center" justifyContent="center" mb={4}>
            <Grid item>
              <Typography variant="body2" color="#fff">
                Monthly Billing
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={billingCycle === "yearly"}
                onChange={handleBillingCycleChange}
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="#fff">
                Yearly Billing <span style={{ fontWeight: "bold" }}>(2 months free)</span>
              </Typography>
            </Grid>
          </Grid>
        </FormControl>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "900px", lg: "1200px" },
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 1),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {cardContent(
                "Free",
                getPrice("free"),
                [
                  "$1.99 per minute",
                  "30 languages",
                  "voice cloning",
                  "500MB max per file",
                  "45min max per file",
                  "expression matching",
                ],
                "dark",
                "free"
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                height: "100%", // Make this card slightly taller
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 1),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(1.05)", // Slightly enlarge this card
              }}
            >
              {cardContent(
                "Standard",
                getPrice("standard"),
                [
                  "$0.99 per minute",
                  "30 languages",
                  "voice cloning",
                  "500MB max per file",
                  "45min max per file",
                  "expression matching",
                ],
                "info",
                "standard"
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 1),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {cardContent(
                "Pro",
                getPrice("pro"),
                [
                  "$0.49 per minute",
                  "30 languages",
                  "voice cloning",
                  "500MB max per file",
                  "45min max per file",
                  "expression matching",
                ],
                "dark",
                "pro"
              )}
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" zIndex={2} mt="auto">
        <SimpleFooter dark />
      </MKBox>
      <SignInModal open={openSignInModal} handleClose={handleCloseSignInModal} />

      {/* PayPal Modal */}
      <Modal
        open={openPayPalModal}
        onClose={handleClosePayPalModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: "lg",
          }}
        >
          <Typography variant="h6" mb={2}>
            Subscribe to {currentSubscriptionType === "standard" ? "Standard" : "Pro"} Plan
          </Typography>
          <PayPalScriptProvider
            options={{
              "client-id":
                "ATk58GP1KKaGL6O7W966iSaw5JQMrEfA6_4zEXd5HRlg5qGwZB74Zp8Roos3OZT7hVmbTvhS8O8GbJPB",
              vault: true,
            }}
          >
            <PayPalButtons
              key={paypalKey}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: getPlanId(currentSubscriptionType),
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                });
              }}
              onApprove={async (data) => {
                const subscriptionId = data.subscriptionID;
                await changeSubscription(currentSubscriptionType, billingCycle, subscriptionId); // Pass subscriptionId to backend
                showModal(
                  `Subscription to ${currentSubscriptionType} (${billingCycle} billing) was successful!`
                );
                handleClosePayPalModal();
              }}
              onError={(err) => {
                console.error("Subscription error:", err);
                showModal("There was an issue with your subscription.");
              }}
            />
          </PayPalScriptProvider>
        </MKBox>
      </Modal>

      <InfoModal
        open={openInfoModal}
        handleClose={() => setOpenInfoModal(false)}
        message={infoMessage}
        title={infoTitle}
      />

      {/* Confirmation Modal for Switching to Free Plan */}
      <Modal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: "lg",
          }}
        >
          <Typography variant="h6" mb={2}>
            Are you sure you want to switch to the Free Plan?
          </Typography>
          <MKBox mt={2} display="flex" justifyContent="space-between">
            <MKButton color="info" onClick={handleCloseConfirmModal}>
              Cancel
            </MKButton>
            <MKButton color="error" onClick={handleFreePlanSwitch}>
              Confirm
            </MKButton>
          </MKBox>
        </MKBox>
      </Modal>
    </MKBox>
  );
}

export default Pricing;
