import React, { useContext, useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import Sidebar from "examples/Navbars/SideBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import { AuthContext } from "contexts/AuthContext";
import { useShopFunctions } from "pages/shop/shopFunctions";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Link, useNavigate } from "react-router-dom";
import SignInModal from "components/SignInModal";
import InfoModal from "components/InfoModal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import languages from "components/languages"; // Adjust the import path accordingly
import { CircularProgress } from "@mui/material";

function DubbingMediaPage() {
  const { user, fetchUser } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [dragOver, setDragOver] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false); // For payment modal
  const [isLoading, setIsLoading] = useState(false); // For processing modal
  const [paypalKey, setPaypalKey] = useState(0); // State to force PayPal buttons to re-render
  const fileInputRef = useRef(null); // Ref for file input
  const [loadingPayment, setLoadingPayment] = useState(false);

  const {
    sourceLanguage,
    videoMetadata,
    dubLanguage,
    progress,
    speakers,
    handleSourceLanguageChange,
    handleDubLanguageChange,
    handleSpeakersChange,
    handleFileChange,
    handleCheckout,
    errorMessage,
    successMessage,
    clearMessages,
    checkoutSuccessful,
    setCheckoutSuccessful,
    cost,
    resetFields, // Reset fields after checkout
  } = useShopFunctions(user);

  useEffect(() => {
    if (!user) {
      setOpenSignInModal(true);
    } else {
      setOpenSignInModal(false);
    }
  }, [user]);

  useEffect(() => {
    // Set up polling interval to fetch user every 5 seconds
    const intervalId = setInterval(() => {
      fetchUser();
    }, 5000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [fetchUser]);

  useEffect(() => {
    if (checkoutSuccessful) {
      setOpenModal(true);
      setIsLoading(false); // Stop the processing modal
      resetFields(); // Reset fields after checkout success
    }
  }, [checkoutSuccessful]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        clearMessages(); // Clear messages after 5 seconds
      }, 5000);

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or updates
    }
  }, [errorMessage, successMessage, clearMessages]);

  const showModal = (message, title = "Notice") => {
    setInfoMessage(message);
    setInfoTitle(title);
    setOpenInfoModal(true);
  };

  const navigate = useNavigate();

  const handleGoToDownloadCenter = () => {
    navigate("/download-center");
    handleCloseModal();
  };

  const handleFileChangeWithCheck = (event) => {
    if (!user) {
      showModal("You need to sign in to upload media.");
      return;
    }
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      handleFileChange(event);
      // Reset the input value to allow re-upload of the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const checkSubscriptionStatus = async () => {
    if (user && user.subscription !== "free") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/subscription-status/${user.subscriptionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          const { status } = await response.json();

          // If the subscription is inactive, switch to "free"
          if (status !== "ACTIVE") {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/subscription`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                newSubscription: "free",
                billingCycle: "none",
                subscriptionId: null,
              }),
              credentials: "include",
            });
            showModal(
              "Your subscription is inactive and has been changed to 'Free'. Please retry."
            );
            return false; // Prevent proceeding to the payment modal
          }
        } else {
          throw new Error("Error fetching subscription status");
        }
      } catch (error) {
        console.error("Subscription status check failed:", error);
        showModal("Unable to verify subscription status. Please try again.");
        return false;
      }
    }
    return true;
  };

  const handleOpenPaymentModalWithChecks = async () => {
    // Check video metadata, languages, and subscription status
    if (!videoMetadata) {
      showModal("Please upload a video file.");
      return;
    }
    if (!sourceLanguage || !dubLanguage) {
      showModal("Please select both source and dub languages.");
      return;
    }

    // Only open payment modal if the subscription check passes
    const canProceed = await checkSubscriptionStatus();
    if (canProceed) {
      setLoadingPayment(true);
      setOpenPaymentModal(true);
      setPaypalKey((prev) => prev + 1); // Increment key to force re-render of PayPal buttons
    }
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    setLoadingPayment(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);

    // Check if the user is signed in
    if (!user) {
      showModal("You need to sign in to upload media.");
      return;
    }

    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const eventMock = {
        target: {
          files: files,
        },
      };
      setSelectedFileName(files[0].name);
      handleFileChangeWithCheck(eventMock);
      // Reset the input value to allow re-upload of the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const handleCloseModal = () => {
    resetFields();
    setSelectedFileName(""); // Reset the displayed file name
    setOpenModal(false);
    setCheckoutSuccessful(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const selectedRoutes = [routes[0], routes[2], routes[3], routes[4]]; // Adjust indices as needed
  const selectedRoutesFoot = [routes[5], routes[1]]; // Adjust indices as needed

  return (
    <MKBox display="flex" minHeight="100vh">
      <Sidebar routes={selectedRoutes} footroutes={selectedRoutesFoot} />
      <MKBox
        sx={{
          marginLeft: "0px", // Use 70px for collapsed sidebar, 240px for expanded
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mt: 5,
        }}
      >
        <MKBox
          borderRadius="lg"
          shadow="xl"
          p={3}
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
            backgroundColor: "white",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            flex: 1, // Make the content area grow and push the footer down
          }}
        >
          <MKTypography variant="h4" textAlign="left">
            Dub Your Media
          </MKTypography>
          <MKTypography variant="body2" textAlign="left">
            Upload your media, select languages, and proceed to checkout
          </MKTypography>

          {errorMessage && (
            <MKTypography variant="body2" color="error" mb={2}>
              {errorMessage}
            </MKTypography>
          )}
          {successMessage && (
            <MKTypography variant="body2" color="success" mb={2}>
              {successMessage}
            </MKTypography>
          )}

          <MKBox mb={4} mt={3}>
            <MKTypography variant="h5" mb={2} color="dark">
              1. Upload Media
            </MKTypography>
            <MKBox
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              sx={{
                position: "relative",
                border: "2px dashed grey",
                borderRadius: "8px",
                padding: "40px 20px",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: dragOver ? "lightgrey" : "transparent",
              }}
            >
              <input
                type="file"
                ref={fileInputRef} // Attach ref to the input element
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={handleFileChangeWithCheck}
              />
              <UploadFileOutlinedIcon fontSize="large" />
              {selectedFileName ? (
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  {selectedFileName}
                </MKTypography>
              ) : (
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  Drag and drop or click to upload
                </MKTypography>
              )}
              <MKTypography variant="body2" color="text" mb={2}>
                Media files, up to 500MB or 45 minutes.
              </MKTypography>
            </MKBox>
            {progress > 0 && (
              <MKBox mb={4}>
                <MKProgress color="info" value={progress} />
                <MKTypography variant="body2" color="text" mt={1}>
                  {progress}% uploaded
                </MKTypography>
              </MKBox>
            )}
          </MKBox>

          <MKBox mb={4}>
            <MKTypography variant="h5" mb={4} color="dark">
              2. Select Languages
            </MKTypography>
            <Grid container spacing={20} justifyContent="center" mb={3}>
              <Grid item xs={12} md={5}>
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  Select Source Language
                </MKTypography>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Source Language</InputLabel>
                  <Select
                    value={sourceLanguage}
                    onChange={handleSourceLanguageChange}
                    label="Source Language"
                    MenuProps={MenuProps}
                  >
                    {Object.entries(languages).map(([code, name]) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  Select Dub Language
                </MKTypography>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Dub Language</InputLabel>
                  <Select
                    value={dubLanguage}
                    onChange={handleDubLanguageChange}
                    label="Dub Language"
                    MenuProps={MenuProps}
                  >
                    {Object.entries(languages).map(([code, name]) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MKBox>

          <MKBox mb={4}>
            <MKTypography variant="h5" mb={4} color="dark">
              3. Select Number of Speakers
            </MKTypography>
            <Grid
              container
              sx={{
                marginLeft: { xs: 0, sm: 0, md: 6.5, lg: 10.5 }, // 0 margin on xs and sm, 10.5 on md and up
              }}
              mb={3}
            >
              <Grid item xs={12} md={5.2}>
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  Select the number of speakers (optional)
                </MKTypography>
                <MKBox mt={1}>
                  <FormControl variant="standard" fullWidth sx={{ maxWidth: "150px" }}>
                    <InputLabel>Number of Speakers</InputLabel>
                    <Select
                      value={speakers}
                      onChange={handleSpeakersChange}
                      label="Number of Speakers"
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={0}>Detect</MenuItem>
                      {[...Array(9).keys()].map((i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>

          <MKBox mb={4}>
            <MKTypography variant="h5" mb={2} color="dark">
              4. Checkout
            </MKTypography>
            <MKTypography variant="body2" color="dark" mb={1}>
              Estimated Cost: ${cost.toFixed(2)}
            </MKTypography>
            {user && (
              <MKTypography variant="caption" color="textSecondary" mb={1}>
                You are currently on the <strong>{user.subscription}</strong> plan, paying{" "}
                <strong>
                  $
                  {user.subscription === "pro"
                    ? 0.49
                    : user.subscription === "standard"
                    ? 0.99
                    : 1.99}
                </strong>{" "}
                per minute. <Link to="/pricing">View other plans</Link>.
              </MKTypography>
            )}
            {/* New MKBox specifically for the Terms of Service text */}
            <MKBox mb={0} mt={-1.6}>
              <MKTypography variant="caption" color="textSecondary" align="center">
                By proceeding, you agree to our{" "}
                <MKTypography
                  variant="caption"
                  component="span"
                  color="info"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/terms-of-service")}
                >
                  Terms of Service
                </MKTypography>
                .
              </MKTypography>
            </MKBox>
            <MKButton
              variant="contained"
              color="info"
              size="large"
              onClick={handleOpenPaymentModalWithChecks}
              sx={{ width: "100%" }}
            >
              Dub Media
            </MKButton>
          </MKBox>
        </MKBox>

        {/* Success Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <MKBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
            borderRadius="lg"
          >
            <MKTypography variant="h6" component="h2">
              Checkout successful
            </MKTypography>
            <MKTypography sx={{ mt: 2 }}>
              Visit your profile to view details and download dubbed media.
            </MKTypography>
            <MKButton
              variant="contained"
              color="primary"
              onClick={handleGoToDownloadCenter}
              sx={{ mt: 2 }}
            >
              Download Center
            </MKButton>
          </MKBox>
        </Modal>

        {/* Payment Modal */}
        <Modal
          open={openPaymentModal}
          onClose={handleClosePaymentModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <MKBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
            borderRadius="lg"
          >
            <MKTypography variant="h5" component="h2" mb={4}>
              Payment Options
            </MKTypography>
            {loadingPayment && (
              <MKBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingY: 2, // Add space above and below the icon
                }}
              >
                <CircularProgress color="info" />
              </MKBox>
            )}
            <PayPalScriptProvider
              options={{
                "client-id":
                  "ATk58GP1KKaGL6O7W966iSaw5JQMrEfA6_4zEXd5HRlg5qGwZB74Zp8Roos3OZT7hVmbTvhS8O8GbJPB",
              }}
            >
              <PayPalButtons
                key={paypalKey}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: cost.toFixed(2),
                        },
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING",
                    },
                  });
                }}
                onInit={() => setLoadingPayment(false)} // Hide spinner once buttons are ready
                onApprove={async (data, actions) => {
                  const order = await actions.order.capture();
                  console.log("Order successful:", order);
                  handleClosePaymentModal();
                  setIsLoading(true);
                  handleCheckout(); // Call your existing checkout logic
                  resetFields(); // Reset the fields after a successful payment
                  setSelectedFileName(""); // Reset the displayed file name
                }}
                onError={(err) => {
                  console.error("PayPal Checkout Error:", err);
                }}
              />
            </PayPalScriptProvider>

            <MKButton
              variant="outlined"
              color="error"
              onClick={handleClosePaymentModal}
              sx={{ mt: 2 }}
            >
              Cancel
            </MKButton>
          </MKBox>
        </Modal>

        {/* Processing Modal */}
        <Modal
          open={isLoading}
          onClose={() => setIsLoading(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <MKBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
            borderRadius="lg"
          >
            <MKTypography variant="h6" component="h2">
              Processing Request...
            </MKTypography>
            <MKTypography sx={{ mt: 2 }}> Please wait while we process your request. </MKTypography>
          </MKBox>
        </Modal>

        <InfoModal
          open={openInfoModal}
          handleClose={() => setOpenInfoModal(false)}
          message={infoMessage}
          title={infoTitle}
        />

        {/* Sign-In Modal using SignInModal Component */}
        <SignInModal open={openSignInModal} handleClose={handleCloseModal} />

        <MKBox width="100%" mt="auto">
          <SimpleFooter dark />
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default DubbingMediaPage;
